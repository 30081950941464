import React from 'react';
import { Link } from 'gatsby';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

const Success = () => {
  return (
    <div id="main_content">
      <Navbar dark={true} />
      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Success</h1>
            Yay! Now you can access the app!
          </div>
          <div style={{ position: 'relative', zIndex: 2, paddingTop: '15px'}}>
            <Link
              className="pix-btn btn-outline"
              to="/getting-started/">
              Getting Started
            </Link>
          </div>          
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Success;
